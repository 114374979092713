import React from 'react';
import s from './Textarea.module.scss';

const Textarea = (props) => (
  <div>
    <textarea className={s.textarea} {...props} />
  </div>
);

export default Textarea;
