import React from 'react';
import { AppContextProvider } from 'contexts/AppContext';
import Contact from 'components/Contact';
import PageLayout from 'components/layout/PageLayout';
import { I18nProvider } from 'i18n';

const ContactPage = () => {
  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout i18nMetaTitle="contact.meta_title" i18nMetaDescription="contact.meta_description">
          <Contact />
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
};

export default ContactPage;
