import React from 'react';
import { useI18n } from 'i18n';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Title from 'components/common/Title';
import Button from 'components/common/Button';
import Input from 'components/common/Form/Input';
import Textarea from 'components/common/Form/Textarea';
import s from './Contact.module.scss';

const Contact = () => {
  const { t } = useI18n();
  return (
    <Grid>
      <Row className={s.row}>
        <Col md={8}>
          <Title>{t('contact.title')}</Title>
        </Col>
      </Row>
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/no-cache=1">
        <Row className={s.row}>
          <Col md={5} lg={4}>
            <Input name="full_name" type="text" id="name" placeholder={t('contact.full_name')} required />
          </Col>
          <Col md={5} lg={4}>
            <Input name="email" type="email" id="email" placeholder={t('contact.email')} required />
          </Col>
          <Col md={10} lg={8}>
            <Textarea name="message" id="message" rows="10" placeholder={t('contact.text')} required />
          </Col>
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <Col md={10} lg={8}>
            <div className={s.btnWrapper}>
              <Button variant="outlined" type="submit">
                {t('contact.submit_btn')}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Grid>
  );
};

export default Contact;
